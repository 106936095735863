import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { DataService } from './data.service'
import { ApiService } from './../../services/api.service'
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
    contents: any = ''
    lang: any
    loadingStatus = false
    content = ''
  constructor(
    private api: ApiService,
    private ds: DataService,
    private sanitizer: DomSanitizer
  ) {
    this.lang = this.api.translate('website.faqs')
    this.lang.subscribe((d: any) => {
        this.lang = d
    })
  }

  ngOnInit() {
    this.loadingStatus = true
    const params = {
        route: 'terms-and-faqs'
    }
    this.ds.getContent(params).subscribe((resp: any) => {
        if (resp.success === true) {
            if (resp.data !== null) {
                this.loadingStatus = false
                this.contents = resp.data[`content_${this.api.getCurrentLang()}`]
            }
            resp.data.forEach((e: any) => {
                if (e.route === 'faqs') {
                    this.content = e.content
                }

            })
        }
    })
}

transformHtml(htmlTextWithStyle: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle)
}

}
