import { DataService } from './/data.service'
import { RouterModule, Routes } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from './../shared/shared.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FaqsComponent } from './faqs.component'

const routes: Routes = [
    { path: '', component: FaqsComponent }
]

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes)
    ],
    declarations: [FaqsComponent],
    providers: [DataService]
})
export class FaqsModule { }
